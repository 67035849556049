.header-container{
    display: flex;
    width: calc(100% - 50px);
    justify-content: space-between;
    align-items: flex-end;
    flex-direction:column;
    margin-bottom: 15px;
		margin-left: 50px;

    .header-title{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        .print-icon{
            cursor: pointer;
            &:active{
                opacity: 0.7;
            }
        }
    }
    .table-name{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 160%;
        /* identical to box height, or 32px */
				height: 44px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.15px;

        color: #000000;
    }
}

.search-filter-container{
    width: 100%;
		max-width: 560px;
    display: flex;
		flex-direction: column;
    align-items: end;
		gap: 15px;
		/* justify-content: flex-start; */
    /* flex-direction: row-reverse; */
}

.search-in-table{
  background-color: rgba(192,200,217,0.17) !important;
  direction: rtl !important;
}

.filter-dot {
	--filter-dot-size: 14px;
	position: absolute;
	
	width: var(--filter-dot-size);
	height: var(--filter-dot-size);
	right: calc(var(--filter-dot-size) * -1 / 4);
	top: calc(var(--filter-dot-size) * -1 / 4);

	border-radius: 50%;
	background-color: rgb(224, 9, 9);

	z-index: 10;

	transition: 0.2s;
}

.MuiButton-root:hover .filter-dot {
	background-color: rgb(141, 16, 16);
}

.easeInFade {
  animation: fadeIn 0.3s ease-in forwards; /* Adjust duration as needed */
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}
