.pagination-container {
    display: grid;
		width: 100%;
    /* justify-content: space-between;
    align-items: center;
    flex: 1; */

		grid-template-columns: 1fr 1fr 1fr;

    .MuiTablePagination-root.table-pagination-1 {
        .MuiTablePagination-displayedRows {
            display: none;
        }

        .MuiTablePagination-actions {
            display: none;
        }
    }
}