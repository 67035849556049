.link{
    text-decoration: "none";
    color:"white";
    font-size: medium;
}

.app-bar-container{
    height: 100% ;
    /* padding: 15px; */
    
    padding: 30px 0px;
    width: 100%;
    .MuiContainer-root{
        height: 100% !important;
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        align-items: center;
    }
    .MuiToolbar-root{
        height: 100% ;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
   .logo{
    margin: 0px 10px 0px 26px;
   }
   .menu-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-end;
    width: 100%;
    /* :hover{
        background-color: "#5C6BC0";
    } */
    .MuiMenuItem-root{
        height: fit-content;
        display: flex;
        justify-content: space-between;
        direction: rtl;
        align-items: flex-start;
        border-radius: 8px 0px 0px 8px;
        padding: 8px 16px 8px 12px;
        flex-direction: column;
        width: 90%;

        .box-header{
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        
       
    }
    .menu-label{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 175%;
        /* identical to box height, or 28px */
        /* color: white; */
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.15px;
        justify-content: flex-end;
        .sub-menu-label{
            color:#000000;
        }
    }
   }

   .sub-menu-container{
    height: 100%;
    width: 100%;
    }
.sub-menu-label{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    padding-right: 15px;
    /* identical to box height, or 20px */
    padding: 12px 32px 12px 16px;

    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.17px;

    /* Common/Black/100p */

    color: #000000;
}
.user-name-side-bar{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    justify-content: center;
    /* identical to box height, or 171% */

    letter-spacing: 0.4px;
    text-transform: uppercase;

    /* M3/read-only/light/white */
    margin: 10px;
    color: #FFFFFF;
    }

    .user-details-container{
        display: flex;
        width: inherit;
        flex-direction: row-reverse;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
				
    }
}
