@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

.new-club-form-table-row {
	display: flex;
	margin-bottom: 10px;
	align-items: stretch;
}

.new-club-form-table-row > :not(:first-child) {
	margin-right: 10px;
}

/* ::-webkit-file-upload-button {
	display: none;
}

::file-selector-button {
  display: none;
} */

.invisible-file-input {
	clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

.title {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 160%;
		display: flex;
		align-items: center;
		text-align: right;
		letter-spacing: 0.15px;
		color: #000000;
}

.sub-title {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 160%;
		display: flex;
		align-items: center;
		justify-content: center;
		letter-spacing: 0.15px;
		color: #000000;
		margin-bottom: 15px;
}

.new-club-page-container {
	overflow: auto;
	padding-bottom: 120px;
}